import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import AppRoutes from "./AppRoutes";
import apollo from "./apollo";
import { AuthProvider } from "./context/auth";
import "react-medium-image-zoom/dist/styles.css";

function App() {
  return (
    <div id="app" className="min-h-screen flex flex-col">
      <ApolloProvider client={apollo}>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
