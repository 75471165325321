import React from "react";
import Zoom from "react-medium-image-zoom";

function Image({ className = "", width = 24, ratio = 1, src = "" }) {
  return (
    <div className={`${className} animate-ease-5 cursor-pointer hover:shadow`}>
      <Zoom wrapStyle={{ outLine: "none" }}>
        <img src={src} alt={src} />
      </Zoom>
    </div>
  );
}

export default Image;
