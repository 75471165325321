import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useDrag, useDrop } from "react-dnd";
import {
  IoIosAddCircleOutline,
  IoIosInformationCircleOutline,
  IoIosRemoveCircleOutline,
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosLink
} from "react-icons/io";
import Button from "./Button";
import Image from "./Image";
import { Text } from "./Form";
import NodeForm from "./NodeForm";
import { NodeFragment } from "../graphql";
import { FETCH_ROOT_NODES } from "../pages/index";
import { FullscreenSpinner } from "./Spinner";
import Errors from "./Errors";
import EmptyDropZone from "./EmptyDropZone";

const ItemTypes = {
  NODE: "node"
};

export const FETCH_NODE = gql`
  query FETCH_NODE($id: ID!) {
    node(id: $id) {
      ...node
    }
  }
  ${NodeFragment}
`;

const DELETE_NODE = gql`
  mutation DELETE_NODE($id: ID!) {
    deleteNode(id: $id)
  }
`;

const MOVE_NODE = gql`
  mutation MOVE_NODE($id: ID!, $parentId: ID!) {
    moveNode(id: $id, parentId: $parentId) {
      ...node
    }
  }
  ${NodeFragment}
`;

const UPDATE_CHILDREN_ORDERING = gql`
  mutation($id: ID!, $ordering: [OrderingInput!]!) {
    updateChildrenOrdering(id: $id, ordering: $ordering) {
      ...node
    }
  }
  ${NodeFragment}
`;

function Node({
  node,
  isLastChild = false,
  isDraggingParent = false,
  showPageLink = true
}) {
  const { id, parentId, title } = node;
  const [showDetail, setShowDetail] = useState(false);
  const [showForm, setShowForm] = useState(null);
  const { loading, error, data } = useQuery(FETCH_NODE, {
    variables: { id }
  });
  const [deleteNode, deleteNodeStatus] = useMutation(DELETE_NODE, {
    variables: { id },
    update: client => {
      if (parentId) {
        const fragmentId = `Node:${parentId}`;
        const fragment = NodeFragment;
        const parentNode = client.readFragment({
          id: fragmentId,
          fragment
        });
        const children = parentNode.children.filter(i => i.id !== id);
        client.writeFragment({
          id: fragmentId,
          fragment,
          data: {
            ...parentNode,
            children,
            hasChildren: children.length > 0
          }
        });
      } else {
        const query = FETCH_ROOT_NODES;
        const data = client.readQuery({ query });
        const rootNodes = data.rootNodes.filter(i => i.id !== id);
        client.writeQuery({
          query,
          data: { rootNodes }
        });
      }
    }
  });
  const [moveNode] = useMutation(MOVE_NODE);
  const [updateChildrenOrdering] = useMutation(UPDATE_CHILDREN_ORDERING);

  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop({
    accept: ItemTypes.NODE,
    drop(dragItem) {
      if (isOverCurrent) {
        moveNode({
          variables: { id: dragItem.node.id, parentId: id }
        });
      }
    },
    canDrop: (item, monitor) => {
      return (
        !isDraggingParent && item.node.id !== id && item.node.parentId !== id
      );
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.NODE, node },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  function onDroppedNodeInFrontOf(droppedNode, inFrontOf) {
    let children = data.node.children.map(({ id, title, ordering }) => ({
      id,
      title,
      ordering
    }));
    const oldOrdering = children.map(i => i.ordering);

    const inFrontOfOrdering = inFrontOf.ordering;
    const droppedNodeOrdering = droppedNode.ordering;

    children = children.map(i => {
      if (i.ordering >= inFrontOfOrdering && i.ordering < droppedNodeOrdering) {
        i.ordering += 1;
      } else if (i.id === droppedNode.id) {
        i.ordering = inFrontOfOrdering;
      }
      return i;
    });

    const newOrdering = children.map(i => i.ordering);

    console.log(oldOrdering, newOrdering);

    if (JSON.stringify(oldOrdering) === newOrdering) return;

    updateChildrenOrdering({
      variables: {
        id,
        ordering: children.map(i => ({
          id: i.id,
          ordering: i.ordering
        }))
      }
    });
  }

  function onDroppedToLast(droppedNode) {
    let children = data.node.children.map(({ id, title, ordering }) => ({
      id,
      title,
      ordering
    }));
    const oldOrdering = children.map(i => i.ordering);
    const droppedNodeOrdering = droppedNode.ordering;
    const length = children.length;
    children = children.map(i => {
      if (i.id === droppedNode.id) {
        i.ordering = length - 1;
      } else if (i.ordering > droppedNodeOrdering) {
        i.ordering -= 1;
      }
      return i;
    });
    const newOrdering = children.map(i => i.ordering);

    if (JSON.stringify(oldOrdering) === newOrdering) return;

    updateChildrenOrdering({
      variables: {
        id,
        ordering: children.map(i => ({ id: i.id, ordering: i.ordering }))
      }
    });
  }

  if (loading)
    return <FullscreenSpinner className="flex py-2" text={null} size={16} />;
  if (error) return <Errors error={error} />;

  const isActive = isOver && canDrop;

  const isDraggingLastChild = isDragging && isLastChild;

  return (
    <div ref={drop}>
      <div
        ref={drag}
        className={`
          animate-cuber p-4 rounded-lg bg-white border border-gray-200 mt-4
          hover:bg-gray-trans-10
          ${isActive ? "" : ""}
          ${isOverCurrent && canDrop ? "bg-blue-200" : ""}
          ${isDragging ? "opacity-25" : ""}
        `}
      >
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <div className="flex">
              <div className="flex">
                <Button
                  title={title}
                  icon={showDetail ? <IoIosArrowDown /> : <IoIosArrowForward />}
                  onClick={() => setShowDetail(prev => !prev)}
                />
              </div>
              {showPageLink ? (
                <Button
                  className="ml-4"
                  link={`/${node.id}`}
                  icon={<IoIosLink />}
                ></Button>
              ) : null}
            </div>
            <input
              className="text-gray-500 bg-transparent text-xs w-full"
              type="text"
              value={id}
              onChange={() => {}}
            />

            {showDetail && node.body ? (
              <Text plain className="text-sm text-gray-600" value={node.body} />
            ) : null}

            {showDetail && node.image ? (
              <div className="flex">
                <Image
                  className="mt-2 mr-2"
                  key={node.image.id}
                  src={node.image.url}
                />
              </div>
            ) : null}
          </div>
          <div className="flex items-center">
            <Button
              title={
                <IoIosAddCircleOutline className="text-green-500 hover:text-green-600 active:text-green-700" />
              }
              onClick={_ => setShowForm(prev => !prev)}
            />
            <Button
              title={
                <IoIosInformationCircleOutline className="text-orange-500 hover:text-orange-600 active:text-orange-700 ml-1" />
              }
              onClick={_ => setShowForm(prev => (prev ? null : node))}
            />
            <Button
              className="ml-1"
              title={
                deleteNodeStatus.loading ? (
                  "deleting..."
                ) : (
                  <IoIosRemoveCircleOutline className="text-red-600 hover:text-red-700 active:text-red-800" />
                )
              }
              onClick={_ => {
                if (window.confirm(`You are sure to delete this node?`))
                  deleteNode();
              }}
            />
          </div>
        </div>
        {showForm ? (
          <div className="p-4 border border-gray-200 rounded-lg bg-gray-100 mt-4">
            <NodeForm
              parentId={id}
              onCancel={() => setShowForm(false)}
              onUpdated={() => {
                setShowForm(false);
                setShowDetail(true);
              }}
              initialNode={showForm === true ? null : showForm}
            />
          </div>
        ) : data.node.children.length > 0 ? (
          <div>
            {showDetail
              ? data.node.children.map(i => (
                  <div key={i.id}>
                    <EmptyDropZone
                      isOverParent={isOver}
                      inFrontOf={i}
                      onDroppedNode={node => onDroppedNodeInFrontOf(node, i)}
                    />
                    <Node
                      isLastChild={
                        data.node.children[data.node.children.length - 1].id ===
                        i.id
                      }
                      node={i}
                      isDraggingParent={isDraggingParent || isDragging}
                    />
                  </div>
                ))
              : null}
            <EmptyDropZone
              isDraggingLastChild={isDraggingLastChild}
              isOverParent={isOver}
              onDroppedToLast={node => onDroppedToLast(node)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Node;
