import React from "react";

export const InlineSpinner = ({
  className = "",
  text,
  color = "#00bbd6",
  thickness = 1,
  size = 32
}) => (
  <div
    className={`${className} flex justify-center items-center flex-col items-center z-30`}
  >
    <div className="lds-ring" style={{ width: size, height: size }}>
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
    </div>
    {text === null ? null : (
      <div className="mt-1 text-gray-600 text-xs">
        {text ? text : "Loading..."}
      </div>
    )}
  </div>
);

export default InlineSpinner;

export const FullscreenSpinner = ({ className = "", ...rest }) => (
  <div
    className={
      className ? className : "flex-1 flex items-center justify-center m-8"
    }
  >
    <InlineSpinner {...rest}></InlineSpinner>
  </div>
);
