import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import settings from "./settings";

const cache = new InMemoryCache({});

const request = async operation => {};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        alert(graphQLErrors.map(i => i.message).join(","));
      }
      if (networkError) {
        alert(networkError.message);
      }
    }),
    requestLink,
    new HttpLink({
      uri: settings.graphqlUri,
      credentials: "include"
    })
  ]),
  cache,
  defaultOptions: {
    mutate: {
      errorPolicy: "all"
    }
  }
});

export default client;
