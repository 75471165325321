import { gql } from "apollo-boost";

export const NodeFragment = gql`
  fragment node on Node {
    id
    parentId
    title
    body
    image {
      id
      url
      thumb(size: 400)
    }
    ordering
    children {
      id
      parentId
      title
      body
      image {
        id
        url
        thumb(size: 400)
      }
      ordering
    }
  }
`;
