import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { DndProvider } from "react-dnd";
import Html5Backend from "react-dnd-html5-backend";
import { IoIosAddCircleOutline } from "react-icons/io";
import Page from "../components/Page";
import { FullscreenSpinner } from "../components/Spinner";
import Errors from "../components/Errors";
import Node from "../components/Node";
import NodeForm from "../components/NodeForm";
import Button from "../components/Button";
import { NodeFragment } from "../graphql";

export const FETCH_ROOT_NODES = gql`
  query FETCH_ROOT_NODES {
    rootNodes {
      ...node
    }
  }
  ${NodeFragment}
`;

function Index() {
  const [showForm, setShowForm] = useState(false);
  const { loading, error, data } = useQuery(FETCH_ROOT_NODES);
  if (loading) return <FullscreenSpinner></FullscreenSpinner>;
  if (error) return <Errors error={error} />;
  const { rootNodes } = data;
  return (
    <Page>
      <div className="m-6 flex-1">
        <DndProvider backend={Html5Backend}>
          {rootNodes.map(i => (
            <Node key={i.id} node={i} />
          ))}
        </DndProvider>
        <div className="mt-6">
          {showForm ? (
            <NodeForm
              onCancel={() => setShowForm(false)}
              onUpdated={() => setShowForm(false)}
            />
          ) : (
            <Button
              title={<IoIosAddCircleOutline className="text-green-500" />}
              onClick={() => setShowForm(true)}
              onCancel={() => setShowForm(false)}
            />
          )}
        </div>
      </div>
    </Page>
  );
}

export default Index;
