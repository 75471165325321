import React, { useState } from "react";
import { uuid } from "uuidv4";

export const FileSelector = ({
  className = "",
  title = "add files",
  onSelect,
  ...rest
}) => {
  const [uid] = useState(uuid());

  function onChangeHandler(e) {
    const file = [...e.target.files][0];
    onSelect(file);
    e.target.value = null;
  }

  return (
    <div className={`${className} overflow-hidden relative`}>
      <label className="cursor-pointer relative text-blue-500" htmlFor={uid}>
        {title}
      </label>
      <input
        id={uid}
        name="file"
        className="hidden"
        type="file"
        onChange={onChangeHandler}
        {...rest}
      />
    </div>
  );
};

export default FileSelector;
