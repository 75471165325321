import React from "react";

function Page({ className = "", children }) {
  return (
    <div
      className={`
        min-h-screen bg-gray-100
        ${className}
    `}
    >
      {children}
    </div>
  );
}

export default Page;
