import React from "react";
import { useLocation } from "react-router-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Index from "./pages/index";
import NodePage from "./pages/Node";
import Login from "./pages/Login";
import { useAuth } from "./context/auth";
import { FullscreenSpinner } from "./components/Spinner";

function PrivateRoute(props) {
  const { user, loading } = useAuth();
  const location = useLocation();
  if (loading) return <FullscreenSpinner />;
  if (!user)
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    );
  return <Route {...props} />;
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login}></Route>
        <Route exact path="/:id" component={NodePage} />
        <PrivateRoute exact path="/" component={Index} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default AppRoutes;
