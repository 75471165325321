import React from "react";
import { useDrop } from "react-dnd";

function EmptyDropZone({
  parent,
  inFrontOf,
  onDroppedNode,
  onDroppedToLast,
  isOverParent,
  isDraggingLastChild = false
}) {
  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop({
    accept: "node",
    drop(dragItem) {
      if (isOverCurrent) {
        if (onDroppedNode) onDroppedNode(dragItem.node);
        if (onDroppedToLast) onDroppedToLast(dragItem.node);
      }
    },
    canDrop: (item, monitor) => {
      if (parent) {
        if (item.node.parentId !== parent.id) return false;
      }
      if (inFrontOf) {
        if (inFrontOf.id === item.node.id) return false;
        if (item.node.ordering + 1 === inFrontOf.ordering) return false;
      }
      return true;
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  });

  const isActive = isOver && canDrop;

  return (
    <div
      ref={drop}
      className={`
        animate-cuber rounded-lg bg-white border-gray-200
        hover:bg-gray-trans-10
        ${
          isOverParent && canDrop && !isDraggingLastChild
            ? "p-2 mt-2"
            : "p-0 mt-0"
        }
        ${isActive ? "p-8" : ""}
        ${isOverCurrent && canDrop ? "bg-blue-200" : ""}
    `}
    ></div>
  );
}

export default EmptyDropZone;
