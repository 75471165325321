import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Spinner from "./Spinner";
import Errors from "./Errors";
import ProgressRing from "./ProgressRing.js";

const FETCH_AWS_PRESIGNED_URL = gql`
  query FETCH_AWS_PRESIGNED_URL($filename: String!, $contentType: String!) {
    awsPreSignedUrl(filename: $filename, contentType: $contentType)
  }
`;

function Uploader({
  className = "",
  awsPreSignedUrl,
  file,
  preview,
  onUploaded
}) {
  const [progress, setProgress] = useState(0);

  function uploadFile() {
    const config = {
      headers: {
        "Content-Type": file.type
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      }
    };
    const http = axios.create(config);
    http
      .put(awsPreSignedUrl, file, config)
      .then(function(res) {
        onUploaded(awsPreSignedUrl);
      })
      .catch(function(err) {});
  }

  useEffect(uploadFile, [file]);

  const uploaded = progress === 100;

  return (
    <div className={`${className} relative`}>
      {preview ? <img className="h-32" src={preview} alt="preview" /> : null}
      <div
        className={`absolute inset-0 z-10 flex items-center justify-center ${
          preview ? (!uploaded ? "bg-dark-50" : "bg-dark-20") : ""
        }`}
      >
        <div style={{ width: 40, height: 40 }}>
          <ProgressRing radius={20} stroke={2} progress={progress} />
        </div>
      </div>
    </div>
  );
}

function AWSUploader({ className = "", file, preview, onUploaded }) {
  const { loading, error, data } = useQuery(FETCH_AWS_PRESIGNED_URL, {
    variables: { filename: file.name, contentType: file.type },
    fetchPolicy: "network-only"
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Uploader
      className={className}
      awsPreSignedUrl={data.awsPreSignedUrl}
      file={file}
      preview={preview}
      onUploaded={onUploaded}
    />
  );
}

export default AWSUploader;
