import React from "react";

export default ({ error }) => {
  console.dir(error);
  let msg = "Unknown error";
  if (error && error.graphQLErrors) {
    msg = error.graphQLErrors.map(e => e.message).join(",");
  }
  return (
    <div className="flex flex-1 justify-center flex-col items-center z-30 text-pink-500 font-bold">
      Error: {msg}
    </div>
  );
};
