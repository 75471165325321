import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const AuthContext = React.createContext();

const FETCH_ME = gql`
  query FETCH_ME {
    user: me {
      email
    }
  }
`;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { loading, error, data } = useQuery(FETCH_ME);
  const [login, loginStatus] = useMutation(
    gql`
      mutation LOGIN($email: String!, $password: String!) {
        user: login(email: $email, password: $password) {
          email
        }
      }
    `,
    {
      update: (_, { data: { user } }) => {
        if (user) return setUser(user);
        alert("Can not login!");
      },
      onError: err => {
        alert(`Can not login!\n${err}`);
      }
    }
  );

  useEffect(() => {
    if (!error && data) setUser(data.user);
  }, [error, data]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        loggingIn: loginStatus.loading,
        user,
        login
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
