import React from "react";
import { Link } from "react-router-dom";

const colors = {
  green: "text-green-500 hover:text-green-600 active:text-green-700",
  blue: "text-blue-500 hover:text-blue-600 active:text-blue-700",
  red: "text-red-500 hover:text-red-600 active:text-red-700",
  yellow: "text-yellow-500 hover:text-yellow-600 active:text-yellow-700",
  gray: "text-gray-500 hover:text-gray-600 active:text-gray-700"
};

function Button({
  className = "",
  title,
  onClick,
  size = "text-sm",
  color = "blue",
  icon = null,
  link = null,
  ...rest
}) {
  const finalClassName = `
      flex items-center
      focus:outline-none
        ${className}
        ${colors[color]}
        ${size}
    `;
  if (link) {
    return (
      <Link className={finalClassName} to={link} {...rest}>
        {icon ? <span className={title ? "ml-2" : ""}>{icon}</span> : null}
      </Link>
    );
  }
  return (
    <button className={finalClassName} onClick={onClick} {...rest}>
      <span>{title}</span>
      {icon ? <span className={title ? "ml-2" : ""}>{icon}</span> : null}
    </button>
  );
}

export default Button;
