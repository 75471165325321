import React from "react";
import { useAuth } from "../context/auth";
import { Input } from "../components/Form";
import Button from "../components/Button";
import { Redirect } from "react-router-dom";
import useForm from "../hooks/userForm";

function Login() {
  const [values, handleChange] = useForm({
    email: process.env.REACT_APP_ADMIN_EMAIL,
    password: process.env.REACT_APP_ADMIN_PASSWORD,
  });
  const { user, login, loggingIn } = useAuth();
  if (user) return <Redirect to="/" />;
  return (
    <div
      className="flex-1 flex items-end bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage:
          "url(https://images.unsplash.com/photo-1587629329288-949a7cbefb89?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2800&q=80)",
      }}
    >
      <div className="pl-16 pb-16">
        <form
          className="mt-6"
          onSubmit={(e) => {
            e.preventDefault();
            login({ variables: values });
          }}
        >
          <Input
            className="block mt-4 border-none"
            placeholder="Email"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <Input
            className="block mt-4 border-none"
            placeholder="Password"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          <Button
            className="block mt-6 text-base mx-2"
            title={loggingIn ? "Signing in..." : "Sign in"}
          ></Button>
        </form>
      </div>
    </div>
  );
}

export default Login;
