import React from "react";
import TextareaAutosize from "react-autosize-textarea";

export function Input({ className = "", ...rest }) {
  return (
    <input
      className={`${className} border rounded-lg border-gray-200 outline-none px-2 py-1`}
      {...rest}
    />
  );
}

export const Text = ({ className = "", plain, ...rest }) => {
  let defaultStyle =
    "w-full p-2 text-sm rounded-lg outline-none border border-gray-200";
  if (plain) {
    defaultStyle = "border-none outline-none w-full";
  }
  return (
    <TextareaAutosize
      rows={3}
      async={true}
      className={`${className} ${defaultStyle}`}
      {...rest}
    />
  );
};
