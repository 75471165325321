import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_NODE } from "../components/Node";
import { useParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import Html5Backend from "react-dnd-html5-backend";
import Page from "../components/Page";
import Button from "../components/Button";
import { FullscreenSpinner } from "../components/Spinner";
import Errors from "../components/Errors";
import Node from "../components/Node";
import { IoMdHome } from "react-icons/io";

function NodePage() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_NODE, {
    variables: { id }
  });
  useEffect(() => {
    if (!data) return;
    document.title = data.node.title;
    return () => {
      document.title = "Caos Node!";
    };
  }, [data]);
  if (loading) return <FullscreenSpinner></FullscreenSpinner>;
  if (error) return <Errors error={error} />;

  return (
    <Page className="m-6">
      <div className="text-lg">
        <Button link="/" icon={<IoMdHome size={24} />}></Button>
      </div>
      <DndProvider backend={Html5Backend}>
        <Node node={data.node} showPageLink={false} />
      </DndProvider>
    </Page>
  );
}

export default NodePage;
