import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Input, Text } from "./Form.js";
import Image from "./Image";
import FileSelector from "./FileSelector";
import useForm from "../hooks/userForm";
import Button from "./Button";
import Alert from "./Alert";
import { NodeFragment } from "../graphql";
import { FETCH_ROOT_NODES } from "../pages/index";
import AWSUploader from "./AWSUploader.js";

const SAVE_NODE = gql`
  mutation SAVE_NODE($node: NodeInput!) {
    saveNode(node: $node) {
      ...node
    }
  }
  ${NodeFragment}
`;

function NodeForm({
  className = "",
  initialNode,
  parentId,
  onCancel,
  onUpdated
}) {
  const [values, updateValues] = useForm({
    title: initialNode ? initialNode.title : "",
    body: initialNode ? initialNode.body : ""
  });
  const [image, setImage] = useState(
    initialNode && initialNode.image ? { url: initialNode.image.url } : null
  );
  const [uploadingImage, setUploadingImage] = useState(null);
  const [saveNode, { loading }] = useMutation(SAVE_NODE, {
    variables: {
      node: {
        id: initialNode ? initialNode.id : null,
        parentId,
        image,
        ...values
      }
    },
    onError: err => {
      Alert(err);
    },
    update: (client, { data: { saveNode } }) => {
      if (initialNode) {
        onUpdated();
        return;
      }
      if (parentId) {
        const id = `Node:${parentId}`;
        const fragment = NodeFragment;
        const node = client.readFragment({ id, fragment });
        const children = node.children
          ? [...node.children, saveNode]
          : [saveNode];
        client.writeFragment({
          id,
          fragment,
          data: {
            ...node,
            children,
            hasChildren: true
          }
        });
      } else {
        const query = FETCH_ROOT_NODES;
        const data = client.readQuery({ query });
        let rootNodes = [...data.rootNodes, saveNode].sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
        client.writeQuery({
          query,
          data: { rootNodes }
        });
      }
      onUpdated();
    }
  });
  return (
    <form
      className={className}
      onSubmit={e => {
        e.preventDefault();
        saveNode();
      }}
    >
      <div className="text-gray-500 text-sm">
        {initialNode ? `Edit ${initialNode.title}` : `Create child`}
      </div>
      <div className="mt-4">
        <Input
          autoFocus
          placeholder="title"
          value={values.title}
          name="title"
          onChange={updateValues}
        />
      </div>
      <div className="mt-2">
        <Text name="body" value={values.body} onChange={updateValues}></Text>
      </div>
      <div className="mt-2">
        <div>
          {uploadingImage ? (
            <div className="w-24 h-24 flex justify-center items-center">
              <AWSUploader
                file={uploadingImage}
                onUploaded={res => {
                  const url = res.split("?")[0];
                  setImage({ url });
                  setUploadingImage(null);
                }}
              />
            </div>
          ) : image ? (
            <div className="border-b py-2 flex justify-between">
              <Image src={image.url} />
              <Button
                className="p-2"
                color="red"
                title="Delete"
                onClick={() => setImage(null)}
              ></Button>
            </div>
          ) : null}
        </div>
        <FileSelector
          className="mt-2"
          title="Select Image"
          onSelect={setUploadingImage}
          accept="image/jpeg"
        />
      </div>
      {!uploadingImage ? (
        <div className="mt-2 flex">
          <Button
            type="submit"
            color="green"
            title={loading ? "Saving..." : "Save"}
          ></Button>
          <Button
            type="button"
            color="gray"
            className="ml-4"
            title="Cancel"
            onClick={onCancel}
          ></Button>
        </div>
      ) : null}
    </form>
  );
}

export default NodeForm;
